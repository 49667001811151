const innoHeight = '160px'
export const innovationRows = [
  {
    image: `signup`,
    title: 'Create your account',
    description: 'You need just 1 minute to register so don’t lose time and be ahead with our technology. Maintain & Improve the health of your crop'
  },
  {
    image: `field3x`,
    title: 'Add your Fields',
    description: 'Add your Fields Subtitle'
  },
  {
    image: `drone3x`,
    title: 'Collect images by drone surveying or Upload your images.',
    description: 'Either you book one of our experts to check your crop or you can upload your images.'
  },
  {
    image: `ai`,
    title: 'AI processing',
    description: 'AI processing Subtitle'
  },
  {
    image: `checkResults`,
    title: 'Check Result',
    description: 'Check Result Subtitle'
  },
  {
    image: `navigateTo`,
    title: 'Navigate to',
    description: 'Navigate to Subtitle'
  },
]
