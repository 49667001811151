import React from 'react'
import { innovationRows } from './innovationData'
import { __ } from '../../../../utils/i18n'
import './Innovation.style.scss'
import Link from 'gatsby-link'
import { getLangLinkPrefix } from 'utils/lang'
import logoLeaf from 'images/logoLeaf.svg'
import signup from 'images/signup.svg'
import drone3x from 'images/drone-3x.webp'
import field3x from 'images/field-3x.webp'
import ai from 'images/ai.svg';
import checkResults from 'images/check-results.svg';
import arrowRight from 'images/arrow-right.svg'
import navigateTo from 'images/navigateTo.jpg'

let innovationIcons = {
  signup,
  field3x,
  drone3x,
  ai,
  checkResults,
  navigateTo
}


const renderInnovationRow = ({ image, title, description, i}, t, showLearnMore = true) => {
  const lang = getLangLinkPrefix(t);

  return (
    <div key={i} className={`row innovation-row row-${i+1}`}>
      <div className="col-12 col-md-4">
        <div className="img-wrap">
          <img src={innovationIcons[image]} alt={title} />
        </div>
      </div>
      <div className="col-12 col-md-8 details">
        <div className="inner">
          <h3 className="mb-0">{__(title, t)}</h3>
          <p className="subtitle">{__(description, t)}</p>
          {showLearnMore ?
            <>
            {title == 'Create your account' ?
              <a href="https://app.farmair.io/signup" rel="noreferrer"
                target="_blank">{__('Sign Up', t)}
                <img src={arrowRight} alt="arrow right" /></a> : null
              // <Link to={`${lang}/how-to-use-it`} className="nav-link">{__("Learn More", t)}
              //   <img src={arrowRight} alt="arrow right" />
              // </Link>
            }
            </>
          : ''}
        </div>
      </div>
    </div>
  )
}

export const renderInnovation = ({t, showLearnMore}) => {
  return (
    <section className="section-innovation bg-green c-white">
      <div className="inner container-sm">
        <div className="num-1-wrap">
          <p className="c-green-light">#1</p>
        </div>
        <h2>{__('Innovation', t)}</h2>
        <p className="subtitle">{__('Innovation Subtitle', t)}</p>
        <a href="https://app.farmair.io/signup" className="m-btn m-btn-white">{__('Create Account', t)}</a>

        <div className="how-to-use-wrap">
          <div className="wrap-inner">
            <h2>{__('How to use it', t)}</h2>
            {innovationRows.map(({ image, title, description }, i) => {
              return renderInnovationRow({ image, title, description, i }, t, showLearnMore)
            })}
                    <div className="leaf-icon">
              <img src={logoLeaf} alt="leaf group" />
            </div>
          </div>

        </div>

      </div>
      <div className="layer-bottom"></div>

    </section>
  )
}